@use 'stylesheets/variables' as *;
@use 'stylesheets/border_classes.module.scss';
@use 'stylesheets/color_classes.module.scss';
@use 'stylesheets/layout_classes.module.scss';
@use 'stylesheets/font_classes.module.scss';

.base {
    position: relative;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    padding: 0;
    text-wrap: nowrap;
    &:disabled {
        color: $white;
        background-color: $lightgray;
    }
}

.rounded {
    @extend .base;
    @extend .fullRounded;
}

.text {
    @extend .base;
    background-color: transparent;
    &:disabled {
        color: $lightgray;
    }
}

.icon {
    @extend .text;
}

.contained {
    @extend .base;
    padding: 0.8rem 2rem;

    &:disabled {
        color: $white;
        background-color: $lightgray;
    }
    &-rounded {
        @extend .contained;
        @extend .rounded;
    }
}

.outlined {
    @extend .base;
    @extend .borderBase;
    padding: 0.8rem 2rem;
    &:disabled {
        color: $lightgray;
        border-color: $lightgray;
    }
    &-rounded {
        @extend .outlined;
        @extend .rounded;
    }
}

.small {
    padding: 0.3rem 1rem;
    font-size: 0.75rem;
}
.medium {
    padding: 0.5rem 2rem;
}

.large {
    padding: 0.8rem 2rem;
    height: 46px;
}

.full-width {
    width: 100%;
}

.loadingContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.hideChildren {
    opacity: 0;
}
