@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';

.alignImg {
    overflow: hidden;
    margin-top: auto;
    margin-left: auto;
    max-height: 500px;

    img {
        margin-left: auto;
        max-width: 90%;
        display: flex;

        @media screen and not (min-height: 750px) {
            max-width: 80%;
        }

        @media screen and not (min-height: 700px) {
            max-width: 63%;
        }
    }
}

@media screen and (min-width: 365px) {
    .boxConatiner {
        display: flex;
        flex-direction: column;
        color: #FFFFFF;
        padding: 1rem;
        margin-top: -30px;
        margin-left: 40px;
        width: 300px;
        border-radius: 10px;
        justify-content: space-between;
    }
}



@media screen and not (min-width: 365px) {
    .boxConatiner {
        display: flex;
        flex-direction: column;
        color: #FFFFFF;
        padding: 1rem;
        margin-top: -30px;
        margin-left: 40px;
        width: 300px;
        border-radius: 10px;
        justify-content: space-between;
    }
}

.alignCount {
    color: #F1F1F2;
    font-size: 12px;
}


.mainConatiner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    position: relative;
}

.btnConatiner {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.alignImgIcon {
    bottom: -39px;
    position: absolute;
    left: 40px;
    transform: rotate(145deg);
}

.alignheaderDsc {
    line-height: 32px;
    margin-bottom: 5px;
}

.arrowIcon {
    margin: 10px 10px 0 10px;
    height: 48px;
}

.pointButton {
    background-color: #3E0047;
    border-radius: 20px;
    width: 100px;
    height: 32px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.absContainer {
    position: absolute;
    top: 150px;
    width: 100%;
}

.digitalCard {
    display: flex;
    flex-direction: row;
    background-color: $white;
    height: 38px;
    padding: 0rem 0.7rem;
    column-gap: 0.5rem;
    align-items: center;

    .headerText {
        color: $black;
    }

    .menu {
        width: 11rem;
        margin-top: -1.5rem;
        margin-left: 1rem;
    }

    .digIconWrapper {
        margin-top: 4px;
        margin-right: 7px;
    }
}

.scancode {
    display: flex;
    margin-top: 1rem !important;
    column-gap: 0.5rem;
}

.scanIcon {
    margin-top: -0.3rem;
}

.scanIconLast {
    margin-top: 0.1rem;
}

.Link {
    text-decoration: underline;
}

.btnAlign {
    width: 128px;
    height: 40px;
}