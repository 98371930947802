@use 'stylesheets/variables' as *;
@use '../base.module.scss';

.screen {
    flex-grow: 1;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    height: 100vh;
    .headerSubTitle {
        text-align: left;
    }
}

.headerLogo {
    margin-top: 2.4rem;
    align-self: self-start;
}

.headerLogoWrapper {
    align-self: flex-start;
    padding: 4rem 0 0 2rem;
}

.dialogContainer {
    position: fixed;
    border-radius: 15px;
    padding-top: 200px;
    background-color: transparent !important;
    margin: -5.5rem 1.5rem 0px !important;

    .title {
        font-size: 1.1rem;
        color: $black;
        font-weight: $bold;
    }
}

.confirmContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.subLink {
    font-size: 12px;
    line-height: 18.18px;
    color: $linkBlue;
    cursor: pointer;
}
.cardCornerPillRight {
    position: relative;
    right: -1.1rem;
    height: 45%;
}
.paddingBottom {
    padding-bottom: 30px;
}
.marginRight {
    margin-right: auto;
}

