@use 'stylesheets/variables' as *;

.headerContainer {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 16px;
    background-color: $purple;
}

.closeButton {
    align-self: flex-end;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.offerListContainer {
    height: 100%;
    overflow: auto;
    padding: 0 16px 16px 16px;
}

.animationContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0);
    z-index: 1000;
}

.signInContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    padding: 3rem 1rem 1rem 1rem;
}
