.paper {
    background: transparent !important;
    margin: 0px !important;
}

.paperScrollPaper {
    width: 100% !important;
    max-height: 100vh !important;
    overflow: hidden;
    justify-content: flex-end !important;
    gap: 30px;
}


.scrollPaper {
    backdrop-filter: blur(2px);
    opacity: 0.9 !important;
}

.paperScrollPaperWithMoreGap {
    max-height: 100vh !important;
    overflow: hidden;
    gap: 70px;
    width: 100%;

    @media screen and (min-height: 700px) {
        gap: 140px;
    }
}

.scrollPaperBpc {
    display: flex;
    align-items: flex-end !important;
    justify-content: center;
    backdrop-filter: blur(2px);
    opacity: 0.9 !important;
}