.content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 0 30px 30px 30px;
}

.buttonContent {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}

.drawerAdornment {
    position: absolute;
    top: 0;
    right: -8px;
}

.startShipments {
    width: 100%;
}
