@use '../base.module.scss' as *;

.newAccountIntroScreenContainer {
    background-image: url('https://de-app.staplesconnect.com/images/img_success_splash.jpg');
    background-size: cover;
    padding: 46px;
    box-sizing: border-box;
    height: 100vh;
    row-gap: 10px;
    position: relative;
}

.topHeader {
    z-index: 1000;
    color: white;
}

.bottomHeader {
    z-index: 1000;
    color: white;
    max-height: 3em;
}

.containerAccIntro {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 50px;
    align-items: center;
    width: 254px;
}