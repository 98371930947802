@use 'stylesheets/variables' as *;

.renderedCardNumber {
    height: 97px;
    width: 100%;
}

.contentWrapper {
    display: flex;
    align-items: center;
    background: $white;
    flex-direction: column;
    height: auto;
    border-radius: 1.2rem;
    padding: 1.2rem 1.5rem;
    position: relative;
    overflow: hidden;
}

.title {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    font-weight: 400;
    margin: 13px 0px 10px 0px;
}

.subtitle {
    padding-top: 0.5rem;
    font-size: 0.75rem;
    padding-bottom: 0.5rem;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
}
.container{
    width: 100%;
    padding-left: 0.5rem;
}
.memberTypeContainer{
    display: flex;
    align-items: center;
    color: grey;
}

.memberName {
    font-weight: 700;
    color: $black;
    font-size: 27.4px;
    line-height: 30.68px;
    // display: flex;
    align-items: center;
    gap: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    // width: 95%;
    white-space: nowrap;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.memberNameIcon {
    vertical-align: top;
    margin-right: 5px;
}

.rewardsNumber {
    font-size: 1.7rem;
    color: $red;
    margin-bottom: 0.4rem;
    letter-spacing: 0.1rem;
}

.learnMore {
    color: $lightblue;
    text-decoration: none;
    font-weight: 500;
    margin-left: 0.2rem;
}

.benefitWrapper {
    padding-top: 0;
    padding-bottom: 0;
}
.cardCornerPill{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 35%;
    height: 35%;
}
.benefitText {
    color: $red;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.812rem;
}

.indicator {
    background-color: lightgray;
    height: 7px;
    width: 50px;
    margin: 0 auto;
    border-radius: 2rem;
    position: absolute;
    top: 10px;
    left: 42%;
}

