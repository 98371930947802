@use 'variables' as *;

// Text colors

.textWhite {
    color: $white;
}

.textLightgray {
    color: $lightgray;
}

.textGray {
    color: $gray;
}

.textMediumGray {
    color: $mediumGray;
}

.textDarkgray {
    color: $darkgray;
}

.textPurple {
    color: $purple;
}

.textRed {
    color: $red;
}

.textBlack {
    color: $black;
}

.textLinkBlue {
    color: $linkBlue;
}
.textGreen {
    color: $lightGreen;
}


.textInherit {
    color: inherit;
}

// Background colors
.bgTransparent {
    background-color: transparent;
}

.bgBlack {
    background-color: $black;
}

.bgWhite {
    background-color: $white;
}

.bgDarkgray {
    background-color: $darkgray;
}

.bgLightgray {
    background-color: $lightgray;
}

.bgPurple {
    background-color: $purple;
}
.bgDarkPurple {
    background-color: $darkPurple;
}

.bgInherit {
    background-color: inherit;
}

.bgRed {
    background-color: $red;
}

.bgDarkBlue {
    background-color: $darkBlue;
}

// Border colors
.borderExtraLightgray {
    border-color: $extraLightgray;
}

.borderLightgray {
    border-color: $lightgray;
}

.borderWhite {
    border-color: $white;
}

.borderPurple {
    border-color: $purple;
}

// Fill colors (to be used by svg icons)
.fillWhite {
    fill: $white;
}

.fillGray {
    fill: $gray;
}

.fillRed {
    fill: $red;
}

.fillBlack {
    fill: $black;
}

.fillPurple {
    fill: $purple;
}

.fillWarnYellow {
    fill: $warnYellow;
}

// Opacity
.opacity0 {
    opacity: 0;
}

.opacity1 {
    opacity: 1;
}
