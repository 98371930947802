@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';

.heading {
  color: $black;
  font-size: 1.25rem !important;
  font-style: normal;
  font-weight: 600 !important;
  letter-spacing: -0.04688rem;
  padding: 1rem 1rem 0rem 1rem;
}
@media only screen and (min-width: 320px) and (max-width: 400px) {
  .slideItem {
    width: 10rem !important;
  }
}
.slideItem {
    width: 12rem;
    background: $white;
    margin: 0 1rem 0 0;
    height: auto;
    border-radius: 10px;
    box-shadow: 1px 1px 2px lightgrey;
  }
  .slideImage {
    height: 10vh;
    img {
      height: 10vh;
    }
  };
  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: center;
    align-items: center;
    position: relative;
    .infoIcon {
      position: absolute;
      top: 10px;
    right: 10px;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    .title {
        font-weight: $bold;
        color: $black;
        font-size: 1.125rem;
        line-height: 1.125rem;
        padding-bottom: 0.8rem;
        width: 10rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-top: 0.3rem;
      }
      .description {
        /* color: $somkyblack;
        font-size: 0.875rem;
        line-height: 1.0625rem;
        padding-bottom: 0.8rem; */
        height: 4rem;
      }
      .activeButton {
        padding: 5px 0;
      }
      .activeButtonText {
       border: none !important;
      }
      .available {
        @extend .description;
        font-size: 0.75rem !important;
        height: 2vh !important;
        // padding-top: 0.5rem;
      }

  }
  .infoWrapper {
    padding: 1rem 2rem;
  }
  .multiUse {
    position: absolute;
    top: 10px;
    left: 10px;
    color: $white;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 700;
    background: $darkBlue;
    border-radius: 6.5rem;
    padding: 2px 10px;
}
.indicator {
  background: $veryLightGray;
  height: 7px;
  width: 20%;
  margin: 0 auto;
  border-radius: 2rem;
  margin-top: 0.5rem;
}
.playerBtn {
  width: 7.5rem;
}
.storeText {
  font-weight: $bold;
}
.textWrap {
  text-wrap: balance;
}
.pillOffer {
  background-color: #f1f1f2;
  margin-bottom: 3px;
  margin-top: 3px;
  text-align: center;
  margin-right: 5px;
}
.pillSection {
  text-wrap: balance;
  display: inline-block;
  padding-right: 8px;
  text-align: left;
  vertical-align: middle;
}
.showExpDate {
  display: inline-block;
  margin-right: 5px;
}