.block {
    display: block;
}

.inline {
    display: inline;
}

.flex {
    display: flex;
}

.inlineFlex {
    display: inline-flex;
}

.flexCol {
    flex-direction: column;
}

.flexRow {
    flex-direction: row;
}

.alignCenter {
    align-items: center;
}

.justifyCenter {
    justify-content: center;
}

.justifySpaceAround {
    justify-content: space-around;
}

.justifySpaceBetween {
    justify-content: space-between;
}

.justifySpaceEvenly {
    justify-content: space-evenly;
}

.alignSelfStart {
    align-self: flex-start;
}

.alignSelfEnd {
    align-self: flex-end;
}

.alignSelfCenter {
    align-self: center;
}

.flexWrap {
    flex-wrap: wrap;
}

.p2 {
    padding: 0.5rem;
}

.p4 {
    padding: 1rem;
}

.p8 {
    padding: 2rem;
}

.py2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.py4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.px4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.px5 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.m0 {
    margin: 0;
}

.mt2 {
    margin-top: 0.5rem;
}

.mt4 {
    margin-top: 1rem;
}

.mb2 {
    margin-bottom: 0.5rem;
}

.mb4 {
    margin-bottom: 1rem;
}

.mb6 {
    margin-bottom: 1.5rem;
}

.mb8 {
    margin-bottom: 2rem;
}

.rowGap2 {
    row-gap: 0.5rem;
}

.rowGap4 {
    row-gap: 1rem;
}

.colGap2 {
    column-gap: 0.5rem;
}

.colGap4 {
    column-gap: 1rem;
}

.fullWidth {
    width: 100%;
}

.screenHeight {
    height: 100vh;
}
