@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';


.scrollPaper {
    display: flex;
    align-items: flex-end !important;
    justify-content: center;
    backdrop-filter: blur(2px);
    opacity: 0.9 !important;
}

.alignImg {
    overflow: hidden;
    width: 100%;
    // position: absolute;
    margin-bottom: auto;
}

.alignImg {
    overflow: hidden;
    width: 100%;
    margin-left: auto;
    max-height: 500px;

    img {
        max-width: 100%;
        display: flex;

        @media screen and not (min-height: 750px) {
            max-width: 80%;
        }
    }
}

.boxConatiner {
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    margin: 40px 20px 10px;
    width: 220px;
    position: absolute;
    border-radius: 10px;
    justify-content: space-between;
    z-index: 10;

    @media screen and (max-width: 340px) {
        width: 180px;
    }
}

.mainConatiner {
    display: flex;
    position: relative;
}

.btnConatiner {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.alignCount {
    color: #F1F1F2;
    font-size: 12px;
}

.alignheaderDsc {
    line-height: 32px;
    margin-bottom: 5px;
}

.arrowIcon {
    position: absolute;
    right: 10px;
    top: -25px;
}

.Link {
    text-decoration: underline;
}

.wrapperConatiner {
    height: 100%;
}

.NextBtn {
    width: 128px;
    height: 40px;
}

.txtContainer {
    width: 200px;
}