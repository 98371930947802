@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';
@use 'stylesheets/layout_classes.module.scss';
@use 'stylesheets/font_classes.module.scss';

.carouselContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
}

.linkAlign {
    display: inline-flex;
    line-height: 16px;
    color: $linkBlue;
}

.confirmContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}
.joinbtn{
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0.8rem 5rem;
}