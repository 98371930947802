@use 'stylesheets/color_classes.module.scss';

.navbar {
    position: sticky;
    bottom: 0;
    min-height: 65px;
    max-height: 65px;
    height: 65px;
    min-width: 100vw;
    width: auto;
    padding: 0;
    margin: 0;
    background: transparent;
    z-index: 500;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
}

.lineSeparator {
    width: 100%;
    height: 1px;
    color: #EDEDED;
}

.gridContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 106px 1fr 1fr;
    box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.07);
}

.iconContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;

    &>svg {
        margin-bottom: 3px;
        height: 26px;
    }
}

.svg {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
}

.navItem {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: white;
    border: none;
    transition-duration: 0.5s;
}

.navItemMiddle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}

.navIndicator {
    position: absolute;
    bottom: 0;
    width: 36px;
    height: 4px;
    border-radius: 10px 10px 0px 0px;
    background: #c00;
    align-self: center;
    transition-duration: 0.5s;
    transform: none !important;
}

.navStaplesLogo {
    width: 28px;
    height: 28px;
    margin-bottom: 6px;
}

// Nav pill position classes
:root {
    --tab-length: calc((100vw - 106px) * 0.25);
}

.home {
    left: calc((var(--tab-length) * 0.5) - 18px);
}

.shop {
    left: calc(((var(--tab-length) * 1.5) - 18px));
}

.rewards {
    left: calc(50vw - 18px);
}

.stores {
    left: calc(((var(--tab-length) * 2.5) + 106px - 18px));
}

.account {
    left: calc(((var(--tab-length) * 3.5) + 106px - 18px));
}