@use 'stylesheets/variables' as *;

.headingContainer {
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 40px 30px 30px 30px;
}

.form {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    row-gap: 1rem;
    margin-bottom: 2rem;
    padding: 0 30px;
}

.fieldContainer {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.label {
    font-family: $light;
}

.formInput {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #ccc;
    outline: none;
    &:focus {
        background: #f2f2f2;
    }
}
