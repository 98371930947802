@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';
@use 'stylesheets/border_classes.module.scss';

.container {
    display: flex;
    flex-direction: column;
    padding: 24px 20px;
    background: #ffffff;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1.5rem;
}

.titleText {
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
}

.mainStoreAlign {
    background: #f2f2f2;
    margin-top: 20px;
    padding: 20px;
}

.nearByStoreContainer {
    margin-top: 20px;
}

.nearByStoreSubContainer {
    padding: 1.25rem 0 1rem;
}

hr {
    border-bottom: 1px solid #d8d8d8;
    margin: 0 -24px;
}

.yourStoreAlign {
    line-height: 1.25rem;
    letter-spacing: -0.4px;
    margin-bottom: 10px !important;
}

.cityStateAlign {
    line-height: 1.625rem;
    letter-spacing: 0.035px;
    margin-bottom: 15px;
}
.address {
    line-height: 1.125rem;
}

.button {
    width: 160px;
    height: 46px;
    padding: 10px;
    background: #000000;
    span {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
    }
}

.storeHoursAndLocation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
}

.btnAlign {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
}

.nearByStoreAlign {
    line-height: 1.5rem;
}

.storeSetSlign {
    font-size: 14px;
    line-height: 1.125rem;
}

.accordianContainer {
    padding: 0;
    background: #f2f2f2;
    margin-bottom: 0 !important;
    .MuiAccordionDetails-root {
        padding: 0 !important;
        background: #f2f2f2 !important;
    }
}

.accordianSummary {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    letter-spacing: -0.37px;
    color: #101820;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hoursDisAlign {
    line-height: 18px;
    letter-spacing: 0em;
    margin-top: 16px;
}
