@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';


.scrollPaper {
    display: flex;
    align-items: flex-end !important;
    justify-content: center;
    backdrop-filter: blur(2px);
    opacity: 0.9 !important;
}

.coinIcon {
    width: 22px;
    height: 22px;
}

.alignImg {
    overflow: hidden;
    margin-top: auto;

    img {
        margin: 0 auto;
        max-width: 70%;
        display: flex;

        @media screen and not (min-height: 700px) {
            max-width: 55%;
        }
    }
}

@media screen and (min-width: 365px) {
    .boxConatiner {
        display: flex;
        flex-direction: column;
        color: #FFFFFF;
        padding: 1rem;
        margin: 65px 10px 25px 20px;
        width: 303px;
        position: absolute;
        right: 0;
        border-radius: 10px;
        justify-content: space-between;

        h2 {
            margin-bottom: 10px;
        }

        p {
            text-align: right;
        }
    }
}



@media screen and not (min-width: 365px) {
    .boxConatiner {
        display: flex;
        flex-direction: column;
        color: #FFFFFF;
        padding: 1rem;
        margin: 65px 10px 25px 20px;
        width: 250px;
        height: 170px;
        position: absolute;
        right: 0;
        border-radius: 10px;
        justify-content: space-between;

        h2 {
            margin-bottom: 10px;
        }

        p {
            text-align: right;
        }
    }
}


.mainConatiner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    position: relative;
}

.btnConatiner {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.alignImgIcon {
    bottom: -39px;
    position: absolute;
    left: 40px;
    transform: rotate(145deg);
}

.alignCount {
    color: #F1F1F2;
    font-size: 12px;
}

.alignheaderDsc {
    line-height: 32px;
    text-align: right;
}

.arrowIcon {
    // right: 95px;
    // position: absolute;
}

.iconBox {
    position: absolute;
    right: 10px;
    top: 15px;
    display: flex;
}

.pointButton {
    background-color: #3E0047;
    border-radius: 20px;
    min-width: 88px;
    height: 32px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
    gap: 8px;
}

.Link {
    text-decoration: underline;
}

.btnAlign {
    width: 128px;
    height: 40px;
}

.imgCoin {
    margin-left: 5px;
}