@use 'stylesheets/variables' as *;
@use '../base.module.scss';

.subheading {
    font-size: 14px;
    color: $black;
    margin: 10px 0 20px 0;
}

.cardSubheading {
    @extend .subheading;
    margin: 0;
    color: inherit;
}

.cardGroupContainer {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 5px;
    justify-content: center;
    padding: 20px;
    text-align: left;
}

.textfieldUnderline > div::after {
    border-bottom-color: $purple !important;
}
