// Colors variables
$white: #ffffff;
$extraLightgray: #e6e6e6;
$veryLightGray: #f2f2f2;
$lightgray: #d3d3d3;
$gray: #757575;
$mediumGray: #585858;
$darkgray: #343434;
$purple: #5b0069;
$darkPurple: #3e0047;
$black: #000000;
$somkyblack: #343434;
$red: #cc0000;
$lightblue: #086dd2;
$darkCyan: #0fbaff;
$smokeywhite: #f2f2f2;
$darkCyanBlue: #101820;
$darkGreen: #0d1812;
$veryLightGray: #d9d9d9;
$linkBlue: #086dd2;
$warnYellow: #f47f20;
$darkBlue: #0058d8;
$lightGreen: #4CCC65;

// Font weights
$light: StaplesNormsRegular;
$normal: StaplesNormsMedium;
$bold: StaplesNormsBold;

// Border radius
$rounded: 8px;
$fullRounded: 500px;
