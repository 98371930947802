@use 'stylesheets/variables' as *;
@use '../base.module.scss' as *;

.selectionContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

@media screen and (min-width: 420px) {
    .cardGroupContainer {
        padding: 0 30px;
    }

    .carouselSection {
        margin: 0 -30px;
    }
}

@media screen and not (min-width: 420px) {
    .cardGroupContainer {
        padding: 0 16px;
    }

    .carouselSection {
        margin: 0 -16px;
    }
}

.cardGroupContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    padding: 8px;
    height: 100%;
}

.cardImage {
    width: 60px;
    height: 60px;

    &Selected {
        @extend .cardImage;
        filter: brightness(0) saturate(100%) invert(10%) sepia(59%) saturate(3817%) hue-rotate(281deg) brightness(109%) contrast(120%);
    }
}

.desText {
    color: var(--monochrome-black, $black);
    font-size: 0.875rem !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 1.25rem !important;
}

.categoryPill {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
}

.subheadingLink {
    font-size: 16px;
    color: $darkgray;
    margin: 0px 0 20px 0;
    color: $linkBlue;
}

.warning {
    border: 1px solid $extraLightgray;
    padding: 3rem;
    border-radius: 0.5rem;
    text-align: center;
}

// FavoriteSelectionIntroScreen

.favoriteSelectionIntroScreenContainer {
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 46px;
}

.catName {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 110px;
}


.btnAlign {
    text-align: center;
    color: #086dd2;
    background: none;
}

.headingAlign {
    text-align: center;
    padding: 2px 11px;
}

.bodyTextAlign {
    text-align: center;
    padding: 2px 11px;
}

.scrollPaper {
    border-radius: 20px;
}