@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';
@use 'stylesheets/layout_classes.module.scss';

.tabs {
    margin: 0 1rem;
    border-bottom: 2px solid $white;

    .tab {
        font-size: 0.8rem;
        font-weight: 500;
        color: $black;
        line-height: 2.1rem;
        width: 33%;
        padding-left: 0;
        padding-right: 0;
        font-family: 'StaplesNormsMedium';
    }
}

.tablePanelBox {
    padding: 0rem 1rem !important;
}

/*.activeAll {
    color: $black;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    padding: 0rem 1rem 1rem 0rem;
    text-decoration-line: underline;
}*/

// ******************************************
.mainCard {
    margin: 1rem 0;
    letter-spacing: normal;
    box-shadow: none !important;

    .gridContainer {
        position: relative;
    }

    .infoIcon {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .multiUse {
        position: absolute;
        top: -6px;
        left: -6px;
        color: $white;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 700;
        background: $darkBlue;
        border-radius: 6.5rem;
        padding: 2px 10px;
    }

    .headerText {
        font-size: 1.625rem;
        font-weight: $bold;
        color: $red;
        margin-top: 0.5rem;
        text-overflow: ellipsis;
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
    }

    // TODO :
    .conImgSection {
        @extend .flex;
        @extend .flexCol;
        @extend .justifySpaceBetween;
        min-height: 9.7rem;

        .dateText {
            text-align: center;
            font-size: 0.75rem;
            margin-top: 0.8rem;
        }

        img {
            vertical-align: middle;
            margin-right: 1rem;
        }
    }

    .imgSection {
        @extend .flex;
        @extend .flexCol;
        @extend .justifySpaceBetween;
        min-height: 9.7rem;

        .dateText {
            text-align: center;
            font-size: 0.75rem;
            width: 80%;
        }

        img {
            vertical-align: middle;
            margin-top: 1rem;
            margin-right: 1rem;
        }
    }

    // TODO::
    .concontentSection {
        @extend .flex;
        @extend .flexCol;
        @extend .justifySpaceBetween;
        min-height: 23vh;
        margin-top: 0.6rem;

        .desText {
            margin: 0rem 0 1rem 0;
            min-height: 3rem;
            color: $somkyblack;
        }

        .btnWrapper {
            display: flex;
            flex-direction: row-reverse;
            /* .btnFontSize {
                font-size:.9rem;
            } */
        }
    }

    .contentSection {
        @extend .flex;
        @extend .flexCol;
        @extend .justifySpaceBetween;
        min-height: 10rem;
        margin-left: 0.8rem;

        .desText {
            margin: 0rem 0 1rem 0;
            min-height: 3rem;
        }

        .btnWrapper {
            display: flex;
            flex-direction: row-reverse;
        }
    }
}

.topBtn {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 999;
}

.noData {
    text-align: center;
    line-height: 30px;

    .desText {
        margin: 0rem 0 0.5rem 0;
        // min-height: 3rem;
        // color: $somkyblack;
        text-align: center;
        font-weight: $bold;
        font-size: 0.875rem;
    }

    .btnWrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        /* .btnFontSize {
            font-size:.9rem;
        } */
    }
}

.headerSubTitle {
    line-height: 24px;
    letter-spacing: 0em;
    padding: 10px 20px;
}

.noCouponsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.availableButton {
    width: 100%;
    margin-top: 0.8rem;
}

.saveuse {
    margin-right: -15px;
}

.saveBtnText {
    font-family: $bold;
    display: flex;
    font-size: 12px;
    line-height: 16px;
}

.tapPadding {
    padding-right: 0;
}