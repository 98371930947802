@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';

body {
    background-color: $veryLightGray;
}
.otherBalanceName {
    color: $white;
    padding-left: 2rem;
    min-height: 5rem;
}
.rewardsSummaryAmount {
    color: '#101820';
    font-weight: 'bold';
    font-size: '1.10rem';
    text-align: 'center';
    margin-top: '0.3rem';
    .doller {
        font-size: 2rem;
        font-weight: 700;
        top: -0.8rem;
        position: relative;
        font-family: 'StaplesNormsBold';
    }
    .amount {
        font-size: 3.7rem;
        font-weight: 700;
        font-family: 'StaplesNormsBold';
        line-height: normal;
    }
    .cent {
        font-size: 2rem;
        font-weight: 700;
        top: -0.8rem;
        font-family: 'StaplesNormsBold';
        position: relative;
    }
}

.classRoomReward {
    padding: 0 1.5rem;
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
    justify-content: space-between;
    align-items: center;
    .classRoomText {
        font-size: 1.312rem;
        font-weight: 500;
        color: #101820;
        font-family: 'StaplesNormsBold';
    }
    .expireText {
        color: #101820;
        font-size: 0.75rem;
    }
    .giveNowButton {
        width: 75%;
        margin-top: 2rem;
        margin-bottom: 8.5rem;
        height: 2.5rem;
        border-radius: 1.885rem;
        background: $black;
        color: $white;
        text-transform: none;
        font-size: 1rem;
    }
}
.iconText {
    color: white;
    margin: 2rem 0 1rem 2rem;
    // line-height: 3rem;
}
.otherText {
    font-size: 1.1rem !important;
    font-weight: 500 !important;
    margin-top: 1rem !important;
}
.headerWrapper {
    display: flex;
    flex-direction: column;
    // background-color: $red;
    // min-height: 25vh;
}
.digitalCard {
    display: flex;
    flex-direction: row;
    background-color: $white;
    height: 3rem;
    padding: 0rem 0.7rem;
}
.digitalMenu {
    @extend .digitalCard;
    column-gap: 5px;
    justify-content: center;
    align-items: center;
    .headerText {
        color: $black;
    }
    .menu {
        width: 11rem;
        margin-top: -1.5rem;
        margin-left: 1rem;
    }
    .digIconWrapper {
        margin-top: 4px;
        margin-right: 7px;
    }
}
.logoWrapper {
    display: flex;
    flex-direction: row;
    color: $white;
    align-items: center;
    padding: 2rem 1.5rem;
    justify-content: space-between;
    .iconWrapper {
        background: $darkPurple;
        border-radius: 1rem;
        padding: 0.6rem 0.8rem 0rem 0.8rem;
    }
    .iconSpan {
        vertical-align: text-top;
    }
    .ptsText {
        font-weight: $bold;
        font-size: 1.1rem;
        margin-top: -0.5rem;
        img {
            vertical-align: middle;
        }
    }
}
.wrapper {
    color: $black;
    margin: 0 1.5rem 1.5rem;
    display: flex;
    background: $white;
    border-radius: 6.5rem;
    // border: 2px solid $white;
    .leftActive,
    .rightActive {
        background-color: $black;
        color: $white;
        font-weight: $bold;
        border-radius: 6.5rem !important;
    }

    .bodyText {
        line-height: 0.9rem;
        font-size: 1rem;
        font-family: $normal;
    }

    .leftBtnWrapper,
    .rightBtnWrapper {
        // background-color: $white;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        padding: 1rem;
        line-height: 0.8rem;
        border-right: 4px solid transparent;
        vertical-align: top;
        flex-grow: 1;
        font-weight: $normal;
    }

    .leftBtnWrapper {
        border-radius: 6.5rem 0 0 6.5rem;
    }

    .rightBtnWrapper {
        border-radius: 0 6.5rem 6.5rem 0;
    }
}
