.title {
  font-size: 16px !important;
  font-weight: 700 !important;
  margin: 16px !important;
}

.quickLinkCard {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 15px;
  margin-right: 8px;
  overflow: initial !important;
}

.cardContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 98px;
  width: 80px;
  border-radius: 15px;
  padding: 10px 12px !important;
}

.cardTitle {
  font-size: 12px !important;
  font-weight: 400 !important;
  text-align: center;
  margin-top: 10px;
}


.quickLinksContainer {
  margin: 16px 12px 10px;
  padding-bottom: 25px;

  h2 {
    margin-bottom: 12px;
  }
}

.quickLinksScrollableAlign {
  padding: 0 !important;
  display: flex;
  align-items: center;
}

.serviceIcon {
  width: 36px;
  height: 36px;
}
