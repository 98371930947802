@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';


.alignImg {
    overflow: hidden;
    margin-top: auto;
    margin-left: auto;
    max-height: 500px;

    img {
        margin-left: auto;
        max-width: 70%;
        display: flex;
    }
}

@media screen and (min-width: 365px) {
    .boxConatiner {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        gap: 10px;
        padding: 1rem;
        right: 0;
        margin: 25px 20px 25px 20px;
        width: 300px;
        height: 200px;
        position: absolute;
        border-radius: 10px;
        justify-content: space-between;
    }
}



@media screen and not (min-width: 365px) {
    .boxConatiner {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        gap: 10px;
        padding: 1rem;
        right: 0;
        margin: 25px 20px 25px 20px;
        width: 300px;
        height: 200px;
        position: absolute;
        border-radius: 10px;
        justify-content: space-between;
    }
}

.alignCount {
    color: #F1F1F2;
    font-size: 12px;
    position: absolute;
    left: 5px;
    top: -25px;
}


.mainConatiner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    position: relative;
}

.btnConatiner {
    display: flex;
    justify-content: space-between;
}

.alignImgIcon {
    bottom: -39px;
    position: absolute;
    left: 40px;
    transform: rotate(145deg);
}

.alignheaderDsc {
    line-height: 32px;
}

.iconBox {
    position: absolute;
    left: 30px;
    top: 210px;
    transform: rotate(180deg);
}

.pointButton {
    background-color: #3E0047;
    border-radius: 20px;
    width: 100px;
    height: 32px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.cardImg {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}

.absContainer {
    position: absolute;
    top: 154px;
    left: 0;
    width: 100%;
    height: 50%;
}

.digitalCard {
    display: flex;
    flex-direction: row;
    background-color: $white;
    height: 3rem;
    padding: 0rem 0.7rem;
    column-gap: 0.5rem;
    align-items: center;

    .headerText {
        color: $black;
    }

    .menu {
        width: 11rem;
        margin-top: -1.5rem;
        margin-left: 1rem;
    }

    .digIconWrapper {
        margin-top: 4px;
        margin-right: 7px;
    }
}

.scancode {
    display: flex;
    margin-top: 1rem !important;
    column-gap: 0.5rem;
}

.scanIcon {
    margin-top: -0.3rem;
}

.scanIconLast {
    margin-top: 0.1rem;
}

.boxWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;

    img {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        padding: 0 15px;
    }

    h2,
    p {
        color: #fff;
    }

    h2 {
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 20px !important;
        max-width: 260px;
        text-align: center;
    }

    button {
        background-color: #CC0000;
        width: 128px;
        height: 40px;
    }
}