@use 'variables' as *;

.light {
    font-family: $light;
}

.normal {
    font-family: $normal;
}

.bold {
    font-family: $bold;
}

.textCenter {
    text-align: center;
}

.textLeft {
    text-align: left;
}

.textRight {
    text-align: right;
}

.textUnderline {
    text-decoration: underline;
}
