@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';


.paper {
    background: transparent !important;
    margin: 0px !important;
}

.paperScrollPaper {
    width: 100% !important;
    max-height: 100vh !important;
    overflow: hidden;
    justify-content: flex-end !important;
    gap: 30px;

}


.scrollPaper {
    backdrop-filter: blur(2px);
    opacity: 0.9 !important;
}

.alignImg {
    overflow: hidden;
    margin-top: auto;
    margin-left: auto;
    max-height: 500px;
    img {
        margin-left: auto;
        max-width: 70%;
        display: flex;
    }
}

@media screen and (min-width: 365px) {
    .boxConatiner {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        gap: 10px;
        padding: 1rem;
        right: 0;
        margin: 25px 20px 25px 20px;
        width: 300px;
        position: absolute;
        border-radius: 10px;
        justify-content: space-between;
    }
}



@media screen and not (min-width: 365px) {
    .boxConatiner {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        gap: 10px;
        padding: 1rem;
        right: 0;
        margin: 25px 20px 25px 20px;
        width: 300px;
        position: absolute;
        border-radius: 10px;
        justify-content: space-between;
    }
}

.alignCount {
    color: #F1F1F2;
    font-size: 12px;
    position: absolute;
    left: 5px;
    top: -25px;
 }


.mainConatiner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    position: relative;
}

.btnConatiner {
    display: flex;
    justify-content: space-between;
}

 .alignImgIcon {
    bottom: -39px;
    position: absolute;
    left: 40px;
    transform: rotate(145deg);
 }

 .alignheaderDsc {
    line-height: 32px;
 }
 .iconBox {
    position: absolute;
    left: 30px;
    top: 210px;
    transform: rotate(180deg);
 }
 .pointButton {
    background-color: #3E0047;
    border-radius: 20px;
    width: 100px;
    height: 32px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
 }
 .cardImg{
    position: relative;
    width: 100%;
    margin-bottom: 20px;
 }
 
 .absContainer {
    position: absolute;
    top: 154px;
    left: 0;
    width: 100%;
    height: 50%;
 }
.boxWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
    img {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        padding: 0px 15px;
    }
    h2,p {
        color: #fff;
    }
    h2 {
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 20px !important;
        max-width: 260px;
        text-align: center;
    }
    button {
        background-color: #CC0000;
        height: 40px;
        width: 128px;
    }
}
.bodyTextAlign {
    width: 200px;
}
