@use 'stylesheets/layout_classes.module.scss';
@use 'stylesheets/font_classes.module.scss';


.description {
    width: 15.8rem;
    color: #343434;
    line-height: 1.25rem;
}

.title {
    line-height: 2rem;
    color: #000000;
}

.btnAlign {
    min-width: 12.5rem;
}
.secondaryBtnAlign {
    line-height: 1.25rem;
}