@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';

.categoryContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 0.5rem;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
    padding: 15px;
    background-color: $white;
    border-radius: $rounded;
    border: none;
    position: relative;
}

.cardSubheading {
    font-size: 12px;
    text-align: center;
    color: $darkgray;
    margin: 0 0 0.5rem 0;
}

.cardInfo {
    text-align: center;
    color: $darkgray;
    font-size: 10px;
    margin: 0;
}

.cardPlusIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 500px;
    border: 2px solid $purple;
    background: $white;
}

.cardImage {
    width: 54px;
    height: 54px;
}

.cardRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 0.5rem;
}

.infoIcon {
    position: absolute;
    top: 5px;
    right: 5px;
}

/* .imgHeading {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    img {
        width: 10vh;
    }
} */
.headerText {
    color: $purple;
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 1.5rem !important;
    /* 150% */
}

.details {
    img {
        width: 8vh;
        vertical-align: top;
        margin-top: -10px;
    }
}

.desText {
    color: var(--monochrome-black, $black);
    font-size: 0.875rem !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 1.25rem !important;
}

.topHeaderText {
    font-size: 1.25rem !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 1.75rem !important;
    /* 140% */
    padding: 2rem 1rem;
}
.indicator {
    background: $veryLightGray;
    height: 7px;
    width: 20%;
    margin: 0 auto;
    border-radius: 2rem;
    margin-top: 0.5rem;
}

.drawerSubContainer {
    height: 100vh;
    overflow-y: auto;
    
}
.accordionSummaryAlign {
  align-items: center;
  gap: 12px;
}

.dateText {
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    letter-spacing: 0em;
    color: #000000 !important;
}