@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';

.alignImg {
    overflow: hidden;
    margin-top: auto;

    img {
        margin: 0 auto;
        max-width: 70%;
        display: flex;

        @media screen and not (min-height: 750px) {
            max-width: 60%;
        }

        @media screen and not (min-height: 700px) {
            max-width: 52%;
        }
    }
}

@media screen and (min-width: 365px) {
    .boxConatiner {
        display: flex;
        flex-direction: column;
        color: #FFFFFF;
        padding: 1rem;
        margin: 25px 20px;
        width: 80%;
        left: 5%;
        position: absolute;
        border-radius: 10px;
        justify-content: space-between;

        h2 {
            margin-bottom: 10px;
        }
    }
}



@media screen and not (min-width: 365px) {
    .boxConatiner {
        display: flex;
        flex-direction: column;
        color: #FFFFFF;
        padding: 1rem;
        margin: 25px 20px;
        width: 80%;
        left: 5%;
        position: absolute;
        border-radius: 10px;
        justify-content: space-between;

        h2 {
            margin-bottom: 10px;
        }
    }
}


.mainConatiner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    position: relative;
}

.btnConatiner {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.alignImgIcon {
    bottom: -39px;
    position: absolute;
    left: 40px;
    transform: rotate(145deg);
}

.alignCount {
    color: #F1F1F2;
    font-size: 12px;
}

.alignheaderDsc {
    line-height: 32px;
}

.arrowIcon {
    position: absolute;
    left: -14px;
    top: -23px;
    transform: rotate(-50deg);
}

.pointButton {
    background-color: #3E0047;
    border-radius: 20px;
    width: 100px;
    height: 32px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.absContainer {
    position: absolute;
    top: 85px;
    left: 0;
    width: 100%;
    height: 50%;
}

.cardImg {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}

.wrapper {
    color: $black;
    margin: 0 1rem 1.25rem 1rem;
    display: flex;
    background: $white;
    border-radius: 6.5rem;
    height: 2.625rem;

    // border: 2px solid $white;
    .leftActive,
    .rightActive {
        background-color: $red;
        color: $white;
        font-weight: $bold;
        border-radius: 6.5rem !important;
    }

    .bodyText {
        line-height: 0.9rem;
        font-size: 1rem;
        font-weight: $bold;
    }

    .leftBtnWrapper,
    .rightBtnWrapper {
        // background-color: $white;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        padding: 1rem;
        line-height: 0.8rem;
        border-right: 4px solid transparent;
        vertical-align: top;
        flex-grow: 1;
        font-weight: $normal;
    }

    .leftBtnWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6.5rem 0 0 6.5rem;
    }

    .rightBtnWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 6.5rem 6.5rem 0;
    }
}

.Link {
    text-decoration: underline;
}

.btnAlign {
    width: 128px;
    height: 40px;
}