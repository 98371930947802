@use '../base.module.scss';
@use 'stylesheets/variables' as *;

.header {
    margin: 0rem 0 1.5rem 0.8rem;
}

.carouselContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    padding-bottom: 1rem;
}

.animationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: white;
}

.textAlign {
    line-height: 16px;
    text-align: center;
    color: #000000;
}

.infoList {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin: 0 1.3rem;
}

.infoListItem {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.infoListIcon {
    min-width: 90px;
}

.topRightDesign {
    position: absolute;
    top: 0;
    right: 0;
}

.disclaimerContainer {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin: 0 .5rem;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.bottomContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    margin: 0 0.8rem;
}
.notInterested{
    margin-top: 25px;
    margin-bottom: 15px;
}

.topGraphic{
    position: relative;
    height: 140px
}

.scrollContent {
    justify-content: space-around;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 140px);
}