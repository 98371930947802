@use 'src/stylesheets/color_classes.module.scss';
@use 'src/stylesheets/border_classes.module.scss';

.container {
    display: flex;
    flex-direction: row;
    column-gap: 2px;
    width: 100%;
}

.pill {
    height: 10px;
    width: 100%;
}
