.headingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../../../assets/bgs/loginBg.svg');
    background-repeat: repeat;
    padding: 5rem 2rem 9rem 2rem;
}

.formContainer {
    margin-top: -5rem;
    padding: 2rem;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1rem;
    button {
        align-self: stretch;
    }
}
