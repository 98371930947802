@use 'stylesheets/layout_classes.module.scss';

.container {
    display: flex;
    flex-direction: column;
    padding: 2rem;

}

.zipCodeForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    margin-top: 8rem;
    #zipcode-modal-input-helper-text {
        color: #C00;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.title {
    line-height: 2rem;
    margin-bottom: 1.125rem;
}

.btnAlign {
    width: 128px !important;
    height: 46px !important;
}

.currentLocationText {
    line-height: 20px; 
}