@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';
@use 'stylesheets/layout_classes.module.scss';

.loader {
    color: $mediumGray;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.errorText {
    color: $black;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    /* 133.333% */
    padding: 1rem;
}

.linkAlign {
    display: inline-flex;
    line-height: 16px;
    color: $linkBlue;
}

.textAlign {
    line-height: 16px;
    text-align: center;
    color: #000000;
}

.listContainer {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.listContainer::-webkit-scrollbar {
    display: none;
}

.heading {
    min-width: 50%;
    font-size: 20px;
    font-family: StaplesNormsMedium;
    margin: 0px;
}

.offerHeader {
    height: 64px;
    background-color: #5b0069;
    padding: 20px 16px;
    display: flex;
    justify-content: space-between;
    position: sticky;
    z-index: 99;
    top: 0;

    .arrowBtn {
        color: white;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;

        > span {
            display: flex;
            align-items: center;
            text-decoration: none;
        }

        img {
            margin-right: 20px;
        }
    }

    .activateAll {
        color: white;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        text-decoration: underline;
    }
}

.offerCapsules {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 16px 16px 0;

    .capsuleBtn {
        background-color: #fff;
        padding: 10px 16px;
        border-radius: 100px;
        min-width: 60px;
        text-align: center;

        > span {
            color: #000;
        }
    }

    .active {
        background-color: #000;

        > span {
            color: #fff;
        }
    }
}

.offerHeading {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
}

.allOffersActivatedText {
    display: flex;
    align-items: center;
}

.activeAll {
    color: $black;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    padding: 0rem 1rem 0rem 0rem;
    text-decoration-line: underline;
}

.backArrow {
    display: flex;
    color: $white;
    font-weight: 500;
    font-family: 'StaplesNormsBold';
    align-items: center;
    span {
        font-size: 16px;
        line-height: 24px;
    }
}
