@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';
@use 'stylesheets/layout_classes.module.scss';

.tabs {
    margin: 0 1rem;

    .tab {
        font-size: 0.875rem;
        font-weight: 500;
        color: $black;
        line-height: 2.1rem;
        width: 50%;
        padding-left: 0;
        padding-right: 0;
        border-bottom: 4px solid $white;
    }
}

.tablePanelBox {
    padding: 1.5rem 1rem !important;
}

.activeAll {
    color: $black;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    padding: 0rem 1rem 0rem 0rem;
    text-decoration-line: underline;
}

.heading {
    min-width: 50%;
    font-size: 20px;
    font-family: StaplesNormsMedium;
    margin: 0px;
}

.topBtn {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 999;
}

.allOffersActivatedText {
    display: flex;
    align-items: center;
}

.listContainer {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.listContainer::-webkit-scrollbar {
    display: none;
}

.offerHeading {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.viewAllBtn > span {
    display: flex;
    align-items: center;
}

.offerViewAll {
    border-bottom: 1px solid #ccc;
    padding: 1rem;

    span {
        color: #000;
        padding-left: 8px;
        text-decoration: underline;
    }
}

.CardBox {
    background: white;
    border-radius: 10px;
    padding: 1rem;
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 160px;
}

.CardDetailsSection {
    display: flex;

    img {
        max-width: 100px;
        margin-right: 20px;
    }

    .CardTextSection {
        min-width: 119px;

        h3 {
            color: #cc0000;
        }
    }
}

.BottomSection {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 5px;

    p {
        color: #585858;
    }

    button {
        width: 130px;
        height: 30px;
    }
}
