@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';
@use 'stylesheets/layout_classes.module.scss';
@use 'stylesheets/font_classes.module.scss';

@media screen and (min-width: 420px) {
    .container {
        padding: 30px;
    }
}

@media screen and not (min-width: 420px) {
    .container {
        padding: 16px;
    }
}

.container {
    box-sizing: border-box;
    background-color: $white;
    height: 100vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    &-centered {
        @extend .footer;
        justify-content: center;
    }
}

.progressBarContainer {
    padding-right: 3rem;
    flex-grow: 1;
    max-width: 180px;
}
