@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';


.alignImg {
    overflow: hidden;
    margin-top: auto;
    margin-left: auto;
    max-height: 500px;
    position: absolute;
    bottom: 430px;

    @media screen and (max-width: 390px) {
        bottom: 390px;
    }

    @media screen and (max-height: 700px) {
        bottom: 310px;
    }

    img {
        margin-left: auto;
        max-width: 200px;
        display: flex;

        @media screen and (max-width: 390px) {
            max-width: 150px;
        }
    }
}

.boxConatiner {
    color: #FFFFFF;
    padding: 1rem 1rem 0 0;
    margin: 0;
    width: 50%;
    border-radius: 10px;
    position: relative;
    margin-top: 5px;

    @media screen and not (min-width:355px) {
        margin-top: 10px;
    }

    h2 {
        margin-bottom: 10px;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
    }
}


.mainConatiner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    position: relative;
}

.btnConatiner {
    display: flex;
    justify-content: space-between;
}

.alignImgIcon {
    bottom: -39px;
    position: absolute;
    left: 40px;
    transform: rotate(145deg);
}

.alignCount {
    color: #F1F1F2;
    font-size: 12px;

    @media screen and (min-width:376px) {
        right: 243px;
    }
}

.alignheaderDsc {
    line-height: 32px;
}

.pointButton {
    background-color: #3E0047;
    border-radius: 20px;
    width: 100px;
    height: 32px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.cardImg {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}

.absContainer {
    position: absolute;
    bottom: 150px;
    left: 0;
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    @media screen and (max-width: 390px) {
        bottom: 110px;
    }

    @media screen and (max-height: 700px) {
        bottom: 30px;
    }
}

.boxConatiner2 {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    gap: 5px;
    padding: 1rem;
    margin: 20px;
    width: 163px;
    min-height: 249px;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    position: relative;

    h2 {
        font-size: 18px !important;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
    }

    p {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 20px;
    }

    img {
        max-width: 90%;
    }
}

.arrowIcon {
    position: absolute;
    right: -67px;
    transform: rotate(0deg);
    bottom: 16px;
}

.cardButton {
    max-width: 150px;
    position: relative;
}

.infoIcon {
    position: absolute;
    top: 5px;
    right: 10px;
}

.Link {
    text-decoration: underline;
}