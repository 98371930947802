@use 'stylesheets/variables' as *;
@use 'stylesheets/layout_classes.module.scss';

.container {
    position: relative;
    max-height: 99vh;
    overflow-y: auto;
}

.contentContainer {
    padding-top: 10px;
}

.indicator {
    position: relative;
    left: 10%;
    top: 0;
    transform: translateX(-50%);
    background: $veryLightGray;
    height: 7px;
    width: 20%;
    border-radius: 2rem;
    margin-top: 0.5rem;
    z-index: 99999;
    margin: 10px auto;
}