@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';

.container {
    height: 100vh;
    overflow: auto;
    overscroll-behavior-y: none;
}

.loaderContainer {
    background-color: $white;
    width: 100vw;
    overflow: hidden;
    border-radius: 0 0 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}