@use 'stylesheets/variables' as *;

.button {
    position: sticky;
    display: block;
    z-index: 1000;
    padding: 0;
    border: none;
    background: transparent;
    height: 0;
    left: 50%;
    transform: translateX(-50%);
}

.buttonContent {
    display: flex;
    align-items: flex-end;
    column-gap: 8px;
    background-color: $purple;
    border-radius: 500px;
    padding: 7px 15px;
}
